<template>
  <div class="cpt-mount cpt-MMC_Gimbal_ZT1">
    <div class="bd">
      <div class="form-wrap mount-form">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div
                class="speed"
                :style="
                  control_speed == index + 1
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_speed"
              size="mini"
              placeholder="请选择控制速度"
            >
              <el-option
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              ></el-option>
            </el-select> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_ptz_mode" v-model="ptz_mode">
              <el-radio
                size="mini"
                v-for="item in drd.ptz_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <el-input
              style="width: 120px"
              v-model.number="pitch"
              size="mini"
              placeholder="请输入俯仰"
            />
            <span class="icon el-icon-remove-outline" @click="pitch--"></span>
            <span class="icon el-icon-circle-plus-outline" @click="pitch++"></span>
            <el-button style="background: linear-gradient(180deg, rgba(28, 91, 197, 0.69) 0%, #091A38 100%);opacity: 0.8;
              border: 1px solid #488CFF;color: #9FC4FF;" @click="handle_change_pitch" size="mini" type="primary"
              >设置</el-button
            >
            <!-- <el-input
              v-model.number="pitch"
              size="mini"
              placeholder="请输入俯仰"
            />
            <el-button @click="handle_change_pitch" size="mini" type="primary"
              >设置</el-button
            > -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input
              style="width: 120px"
              v-model.number="yaw"
              size="mini"
              placeholder="请输入航向"
            />
            <span class="icon el-icon-remove-outline" @click="yaw--"></span>
            <span class="icon el-icon-circle-plus-outline" @click="yaw++"></span>
            <el-button style="background: linear-gradient(180deg, rgba(28, 91, 197, 0.69) 0%, #091A38 100%);opacity: 0.8;
              border: 1px solid #488CFF;color: #9FC4FF;" @click="handle_change_yaw" size="mini" type="primary"
              >设置</el-button
            >
            <!-- <el-input
              v-model.number="yaw"
              size="mini"
              placeholder="请输入俯仰"
            />
            <el-button @click="handle_change_yaw" size="mini" type="primary"
              >设置</el-button
            > -->
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="title-box">
          <div class="title">控&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;制</div>
        </div>
        <div class="rocker-box">
          <div class="rocker" ref="rocker"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nipplejs from "nipplejs";
import { MMC_Gimbal_ZT1 } from "../utils";
export default {
  props: {
    whachChange:{
      typeof:Number,
      default:0
    }
  },
  data() {
    return {
      joystick:null,
      control_speed: 3,
      ptz_mode: 0,
      pitch: 0,
      yaw: 0,
      drd: {
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
      },
    };
  },
  methods: {
    handle_change_ptz_mode(value) {
      let buffer = MMC_Gimbal_ZT1.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_pitch() {
      let buffer = MMC_Gimbal_ZT1.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      let buffer = MMC_Gimbal_ZT1.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      // console.log(buffer);
      this.$emit("directive", buffer);
    },
    init_rocker() {
      this.joystick = nipplejs.create({
        zone: this.$refs["rocker"],
        mode: "static",
        position: {
          left: "50%",
          top: "50%",
        },
        color: "#9bacbe",
      });

      this.joystick
        .on("start", this.start_timer)
        .on("end", this.stop_timer)
        .on(
          "dir:up plain:up dir:left plain:left dir:down plain:down dir:right plain:right",
          (evt, data) => {
            const dir = data.direction.angle;
            switch (dir) {
              case "up":
                this.buffer = MMC_Gimbal_ZT1.gimbal_pitch_ctrl(
                  1,
                  this.control_speed
                );
                break;
              case "right":
                this.buffer = MMC_Gimbal_ZT1.gimbal_yaw_ctrl(
                  1,
                  this.control_speed
                );
                break;
              case "left":
                this.buffer = MMC_Gimbal_ZT1.gimbal_yaw_ctrl(
                  -1,
                  this.control_speed
                );
                break;
              case "down":
                this.buffer = MMC_Gimbal_ZT1.gimbal_pitch_ctrl(
                  -1,
                  this.control_speed
                );
            }
          }
        );
    },
    start_timer() {
      this.buffer = null;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.buffer) {
            this.commit_directive(this.buffer);
          }
        }, 200);
      }
    },
    stop_timer() {
      this.buffer = MMC_Gimbal_ZT1.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      this.buffer = MMC_Gimbal_ZT1.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      if (this.buffer) {
        this.buffer = null;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  watch:{
    whachChange(){
      if(this.joystick){
        this.joystick.destroy()
      }
      this.init_rocker()
    }
  },
  mounted() {
    this.$emit("width", '416px')
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  beforeDestroy() {
    this.stop_timer();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/index.scss";
</style>